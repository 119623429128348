import { PageScriptGroup } from '@aurora/shared-generated/types/graphql-schema-types';
import type {
  ComponentScript,
  ComponentScriptGroups,
  PageScriptGroupDefinition,
  ComponentScriptGroupsDefinition
} from '@aurora/shared-generated/types/graphql-schema-types';
import NextGenUrlBuilder from '@aurora/shared-utils/helpers/urls/UrlHelper/NextGenUrlBuilder';
import type { ScriptTag } from './PageScriptsProvider';
import { getLog } from '@aurora/shared-utils/log';

const log = getLog(module);

interface ScriptTagGroups {
  [PageScriptGroup.AfterInteractive]: ScriptTag[];
  [PageScriptGroup.LazyOnLoad]: ScriptTag[];
}

function getScript(group: PageScriptGroupDefinition, componentScript: ComponentScript): ScriptTag {
  const { id, url } = componentScript;
  const scriptUrlBuilder = NextGenUrlBuilder.fromUrl(url);
  const source = scriptUrlBuilder.build();
  log.debug('Loading component script %s with src %s in group %s', id, source, group.group);
  return {
    id,
    src: source
  };
}

function getGroupDefinition(
  groupsDefinition: ComponentScriptGroupsDefinition,
  pageScriptGroup: PageScriptGroup
): PageScriptGroupDefinition {
  switch (pageScriptGroup) {
    case PageScriptGroup.AfterInteractive: {
      return groupsDefinition.afterInteractive;
    }
    case PageScriptGroup.LazyOnLoad: {
      return groupsDefinition.lazyOnLoad;
    }
  }
}

function getScripts(
  componentScriptGroups: ComponentScriptGroups,
  componentScriptGroup: PageScriptGroup
): ScriptTag[] {
  if (!componentScriptGroups) {
    return [];
  }
  const { scriptGroups: scriptGroupsDefinition, componentScripts } = componentScriptGroups;
  if (componentScripts?.length === 0) {
    return [];
  } else {
    const groupDefinition = getGroupDefinition(scriptGroupsDefinition, componentScriptGroup);
    return (
      groupDefinition.scriptIds
        .map(componentScriptId => {
          const componentScript = componentScripts.find(
            script => script.id.slice(0, -3) === componentScriptId
          );
          if (!componentScript) {
            return null;
          }
          return getScript(groupDefinition, componentScript);
        })
        .filter(o => !!o) ?? []
    );
  }
}

function getComponentScriptTagGroups(
  componentScriptGroups: ComponentScriptGroups
): ScriptTagGroups {
  return {
    [PageScriptGroup.AfterInteractive]: getScripts(
      componentScriptGroups,
      PageScriptGroup.AfterInteractive
    ),
    [PageScriptGroup.LazyOnLoad]: getScripts(componentScriptGroups, PageScriptGroup.LazyOnLoad)
  };
}

export { getComponentScriptTagGroups };
