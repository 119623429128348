import type { ListVariantTypeAndProps } from '@aurora/shared-client/components/common/List';
import type { PagerVariantTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import type { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import type { User } from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import type { ItemType, UserViewVariant } from '../../types/enums';
import type { UserSearchQuery, UserSearchViewFragment } from '../../types/graphql-types';
import type { ItemListCommonProps } from '../common/List/ItemList';
import type { ComponentCommonProps, WidgetProps } from '../common/Widget/types';
import type { ItemViewVariantProps, ItemViewTypeAndProps } from '../entities/types';
import type { SearchItemType } from '../search/enums';

export type UserList = ItemListCommonProps<
  User,
  ItemViewTypeAndProps<ItemType.USER, UserViewVariant>
>;

export interface UserListWidget extends ComponentCommonProps, WidgetProps, UserList {
  /**
   * Whether to show title or not
   */
  useTitle?: boolean;

  /**
   * Type of panel to use for widget
   */
  panelType?: PanelType;

  /**
   * Whether to show footer or not
   */
  useFooter?: boolean;
}

/**
 * The user group(s) a widget is visible to.
 */
export enum WidgetVisibilityGroup {
  /**
   * Only signed-in users can view the widget.
   */
  SIGNED_IN_ONLY = 'signedInOnly',
  /**
   * Only anonymous (not signed in) users can view the widget.
   */
  ANONYMOUS_ONLY = 'anonymousOnly',
  /**
   * Both anonymous and signed-in users can view the widget.
   */
  SIGNED_IN_OR_ANONYMOUS = 'signedInOrAnonymous'
}

export interface UserListForSearchTermProps {
  /**
   * Callback that triggers when useQuery is success.
   *
   * @callback
   * @param results from useQuery graphQl call
   */
  onCompleted?: (searchResults: UserSearchQuery) => void;
  /**
   * Property to pass the term to query.
   */
  queryTerm?: string;
  /**
   * The list variant type and props to use.
   */
  listVariant: ListVariantTypeAndProps<UserSearchViewFragment>;
  /**
   * User view variant props overrides.
   */
  userViewVariantPropsOverrides?: ItemViewVariantProps<
    ItemType.USER_SEARCH,
    UserViewVariant.INLINE
  >;
  /**
   * Pager variant type and props.
   */
  pagerVariant?: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams>;
  /**
   * Page size.
   */
  pageSize?: number;
  /**
   * Classname for the paneled item list component.
   */
  paneledListClassname?: string;
  /**
   * Classname for the panel header section.
   */
  headerClassname?: string;
  /**
   * Classname for the panel footer section.
   */
  footerClassname?: string;
  /**
   * Applies panel styling to the component.
   */
  panel?: PanelType;
  /**
   * Whether to display empty state or not.
   */
  useEmpty?: boolean;
  /**
   * Callback triggered when component render is done.
   *
   * @callback
   * @param pageNumber page number of the results.
   * @param type mention item type.
   */
  onRendered?: (pageNumber: number, type: SearchItemType) => void;
  /**
   * Constrain number of pages.
   */
  constrainPages?: number;
  /**
   * Whether to use header for the PaneledItemList.
   */
  useHeader?: boolean;
}
