import dynamic from 'next/dynamic';
import type React from 'react';

export enum SharedEndUserComponent {
  PLACES_WIDGET = 'places.widget.PlacesWidget',
  AVATAR = 'common.avatar.Avatar',
  BANNER = 'common.banner.Banner',
  MESSAGE_VIEW = 'messages.MessageView',
  ITEM_LIST = 'common.list.ItemList',
  USER_VIEW = 'user.UserView',
  BADGE_VIEW_ICON = 'user.BadgeViewIcon'
}

interface SharedComponentDescriptor {
  id: SharedEndUserComponent;
  component: React.ComponentType;
}

/**
 * Component Registry that holds the records of the shared components that can be referenced by
 * a custom component template.
 *
 * @author Patricio Lugli
 */
class SharedCoreComponentRegistry {
  private readonly widgetRecord: Partial<
    Record<SharedEndUserComponent, SharedComponentDescriptor>
  > = {};

  constructor() {
    SharedCoreComponentRegistry.getSharedComponents().forEach(descriptor => {
      this.widgetRecord[descriptor.id] = descriptor;
    });
  }

  private static getSharedComponents(): SharedComponentDescriptor[] {
    return [
      {
        id: SharedEndUserComponent.PLACES_WIDGET,
        component: dynamic(() => import('../components/shared/SharedPlacesWidgetWrapper'))
      },
      {
        id: SharedEndUserComponent.AVATAR,
        component: dynamic(
          () => import('@aurora/shared-client/components/shared/SharedAvatarWrapper')
        )
      },
      {
        id: SharedEndUserComponent.BANNER,
        component: dynamic(
          () => import('@aurora/shared-client/components/shared/SharedBannerWrapper')
        )
      },
      {
        id: SharedEndUserComponent.MESSAGE_VIEW,
        component: dynamic(() => import('../components/shared/SharedMessageViewWrapper'))
      },
      {
        id: SharedEndUserComponent.ITEM_LIST,
        component: dynamic(() => import('../components/shared/SharedItemListWrapper'))
      },
      {
        id: SharedEndUserComponent.USER_VIEW,
        component: dynamic(() => import('../components/shared/SharedUserViewWrapper'))
      }
    ];
  }

  getWidgetDescriptor(id: SharedEndUserComponent): SharedComponentDescriptor {
    return this.widgetRecord[id];
  }
}

const sharedCoreComponentRegistry = new SharedCoreComponentRegistry();
export default sharedCoreComponentRegistry;
