import React from 'react';
import type { ComponentScriptGroups } from '@aurora/shared-generated/types/graphql-schema-types';
import { PageScriptGroup } from '@aurora/shared-generated/types/graphql-schema-types';
import Script from 'next/script';
import { getComponentScriptTagGroups } from '../../context/ScriptsContext/ComponentScriptsProvider';

interface Props {
  /**
   * The script group tag to be fetched
   */
  scriptGroups: ComponentScriptGroups;
}

const CustomComponentScripts: React.FC<React.PropsWithChildren<Props>> = ({ scriptGroups }) => {
  const scriptGroupsTag = getComponentScriptTagGroups(scriptGroups);

  return (
    <React.Fragment>
      {scriptGroupsTag[PageScriptGroup.AfterInteractive]?.map(({ id, src }) => (
        <Script id={id} key={id} src={src} strategy={'afterInteractive'} />
      ))}
      {scriptGroupsTag[PageScriptGroup.LazyOnLoad]?.map(({ id, src }) => (
        <Script id={id} key={id} src={src} strategy={'lazyOnload'} />
      ))}
    </React.Fragment>
  );
};

export default CustomComponentScripts;
