import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import AppContext from '@aurora/shared-client/components/context/AppContext/AppContext';
import useQueryWithTracing from '@aurora/shared-client/components/useQueryWithTracing';
import type { ComponentProp } from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import {
  getTextParamsFromI18Expansion,
  getComponentContextProps,
  getTextKeyFromI18Expansion
} from '@aurora/shared-client/helpers/components/CustomComponentsHelper';
import type { ComponentScriptGroups } from '@aurora/shared-generated/types/graphql-schema-types';
import { ComponentMarkupLanguage } from '@aurora/shared-generated/types/graphql-schema-types';
import type { CachedComponent, GlobalCss } from '@aurora/shared-types/components';
import type { FormatMessage } from '@aurora/shared-types/texts';
import { collapseWhitespace } from '@aurora/shared-utils/helpers/objects/StringHelper';
import { getLog } from '@aurora/shared-utils/log';
import type { DOMNode } from 'html-react-parser';
import HTMLReactParser from 'html-react-parser';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import Script from 'next/script';
import React, { useContext } from 'react';
import type { SharedEndUserComponent } from '../../../features/sharedCoreComponentRegistry';
import sharedCoreComponentRegistry from '../../../features/sharedCoreComponentRegistry';
import type {
  RenderCustomComponentQuery,
  RenderCustomComponentQueryVariables
} from '../../../types/graphql-types';
import EditContext from '../../context/EditContext/EditContext';
import useCustomComponentTranslation from '../../useCustomComponentTranslation';
import renderCustomComponent from '../RenderCustomComponent.query.graphql';
import CustomComponentScripts from './CustomComponentScripts';
import HtmlContent from './HtmlContent';

const log = getLog(module);

const ErrorAlert = dynamic(() => import('./ErrorAlert'));

interface DOMElement {
  name: string;
  attribs: Record<string, unknown>;
  children: DOMElement[];
  data: unknown;
}

const transform = function transform(
  node: DOMNode & DOMElement,
  globalCss: GlobalCss,
  formatMessage: FormatMessage
) {
  if (node.type === 'script') {
    const body = node.children;
    if (body.length > 0) {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Script {...node.attribs} strategy={'afterInteractive'}>{`${body[0].data}`}</Script>;
    } else {
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Script {...node.attribs} strategy={'afterInteractive'} />;
    }
  } else if (node.type === 'tag') {
    switch (node.name) {
      case 'li:component': {
        const widget = sharedCoreComponentRegistry.getWidgetDescriptor(
          node.attribs?.id as SharedEndUserComponent
        );
        const props = node.attribs.props ? JSON.parse(`${node.attribs.props}`) : {};
        if (widget) {
          log.debug('rendering shared component %s for custom component', node.attribs.id);
          const WrappedComponent = widget.component;
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <WrappedComponent {...props} />;
        }
        log.debug('shared component with id: %s not found', node.attribs?.id);
        break;
      }
      case 'li:i18n': {
        let values = {};
        if (node.attribs.values) {
          try {
            values = JSON.parse(`${node.attribs.values}`);
          } catch (error) {
            log.error('Failed to parse i18n values for custom component.', error);
          }
        }
        const message = formatMessage(node.attribs.key, values);
        return <>{message}</>;
      }
      default: {
        if (node.attribs) {
          if (node.attribs['class']) {
            const collapsedClassNames = collapseWhitespace(`${node.attribs['class']}`);
            if (collapsedClassNames.includes(' ')) {
              node.attribs['class'] = collapsedClassNames
                .split(' ')
                .map(className => globalCss?.tokens[className] || className)
                .join(' ');
            } else {
              const globalClassName = globalCss?.tokens[collapsedClassNames];
              if (globalClassName) {
                node.attribs['class'] = globalClassName;
              }
            }
          }
          Object.entries(node.attribs).forEach(([key, value]) => {
            if ((value as string).startsWith('<li:i18n')) {
              const textKey = getTextKeyFromI18Expansion(value as string);
              if (textKey) {
                const params = getTextParamsFromI18Expansion(value as string);
                node.attribs[key] = formatMessage(textKey, params);
              }
            }
          });
          return node;
        }
      }
    }
  }
};

interface Props {
  /**
   * The component.
   */
  cachedComponent: CachedComponent;

  /**
   * Specifies any props to pass to the custom component.
   */
  customComponentProps?: Array<ComponentProp>;
}

function getTemplateContent(
  templateId: string,
  globalCss: GlobalCss,
  formatMessage: FormatMessage,
  markup: string,
  scriptGroups: ComponentScriptGroups
): React.ReactElement {
  if (!markup) {
    log.warn('No markup found for custom component: %s', templateId);
  }
  return (
    <>
      {globalCss?.css && (
        <Head key={templateId}>
          <style data-testid="CustomComponentContentCss" type="text/css">
            {globalCss?.css}
          </style>
          <CustomComponentScripts scriptGroups={scriptGroups} />
        </Head>
      )}
      {markup &&
        HTMLReactParser(markup, {
          replace: node => {
            return transform(node as DOMNode & DOMElement, globalCss, formatMessage);
          }
        })}
    </>
  );
}

const CustomComponentContent: React.FC<React.PropsWithChildren<Props>> = ({
  cachedComponent,
  customComponentProps
}) => {
  const { component, globalCss } = cachedComponent;
  const { id: customComponentId } = component;
  const language = component?.template.markupLanguage;
  const { pageTemplateContext } = useContext(AppContext);

  const queryResult = useQueryWithTracing<
    RenderCustomComponentQuery,
    RenderCustomComponentQueryVariables
  >(module, renderCustomComponent, {
    variables: {
      componentId: customComponentId,
      context: {
        page: pageTemplateContext,
        component: {
          props: getComponentContextProps(customComponentProps),
          entities: []
        }
      }
    }
  });

  const { showEditControls } = useContext(EditContext);
  const { loading, data, error: queryError } = queryResult;
  const i18n = useCustomComponentTranslation(customComponentId, component.template.texts);
  const { formatMessage } = i18n;
  const markup = data?.component?.render?.html;
  const scriptGroups = data?.componentScriptGroups;

  if (queryError) {
    log.error(
      `Unable to fetch component to render for component id ${customComponentId}: ${queryError.message}`
    );
    if (showEditControls) {
      return <ErrorAlert customComponentId={customComponentId} error={queryError} />;
    } else {
      return null;
    }
  }

  if (!loading && !data?.component) {
    log.error('Component with id: %s not found', customComponentId);
    return null;
  }

  if (loading) {
    return null;
  }

  const templateId = cachedComponent.component.template.id;
  return (
    <QueryHandler<RenderCustomComponentQuery, RenderCustomComponentQueryVariables>
      queryResult={queryResult}
    >
      {(): React.ReactNode => {
        if (queryError) {
          log.error('Unable to load custom component.', queryError);
          return null;
        }
        return language === ComponentMarkupLanguage.Html ? (
          <HtmlContent markup={markup} scriptGroups={scriptGroups} />
        ) : (
          getTemplateContent(templateId, globalCss, formatMessage, markup, scriptGroups)
        );
      }}
    </QueryHandler>
  );
};

export default CustomComponentContent;
